import { Button, Checkbox, DatePicker, Divider, Form, Input, Modal, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { STATUS_PROSES_PACKING } from '../../../../constants'
import OrderService from '../../../../services/OrderService'

const InputResi = (props) => {
  const { open, toggle, row } = props

  const [form] = Form.useForm()

  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [orderId, setOrderId] = useState(null)
  const [shippingDate, setShippingDate] = useState(null)
  const [checkedItems, setCheckedItems] = useState([])

  const checkAll = items?.length === checkedItems?.length
  const indeterminate = checkedItems?.length > 0 && checkedItems?.length < items?.length

  useEffect(() => {
    setOrderId(row?.order.id)
    setItems(
      row?.order.items?.map((item) => ({
        value: item.item_id,
        label: (
          <p className="text-xs">
            {item.item_id +
              ' - ' +
              [item.product?.name, item.vehicle?.row, item.vehicle?.vehicle_model.name, item.year]
                .map((e) => e)
                .join(' ')}
          </p>
        ),
        disabled: item.awb !== null || item.status.id !== STATUS_PROSES_PACKING,
      }))
    )

    if (row?.order.items.length === 1) {
      setCheckedItems([row.order.items[0].item_id])
    }

    form.setFieldsValue({
      awb: row?.awb,
    })
  }, [row, form])

  const handleReset = () => {
    form.resetFields()
    setOrderId(null)
    setShippingDate(null)
    setCheckedItems([])
    toggle()
  }

  const onChange = (list) => {
    setCheckedItems(list)
  }

  const onCheckAllChange = (e) => {
    setCheckedItems(
      e.target.checked ? items.map((e) => (!e.disabled ? e.value : null)).filter((e) => e) : []
    )
  }

  const onFinish = async (values) => {
    const body = {
      awb: values.awb,
      shipping_date: shippingDate,
      order_id: orderId,
      items: checkedItems,
    }
    setLoading(true)
    try {
      const response = await OrderService.setTrackingNumber(body)
      if (response.status === 200) {
        notification.success({
          message: 'Berhasil',
          description: 'Resi berhasil di input',
          placement: 'top',
        })
      }
      handleReset()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal forceRender open={open} centered={true} onCancel={handleReset} footer={false}>
      {row && (
        <h2 className="text-xl text-gray-800 font-medium">Pengiriman {row.order.order_number}</h2>
      )}

      <Form
        form={form}
        onFinish={onFinish}
        className="section-form-recipient mt-10"
        layout="vertical"
      >
        <div className="grid md:grid-cols-2 gap-3">
          <Form.Item
            label="Nomor Resi"
            name="awb"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="JP123xxx" />
          </Form.Item>

          <Form.Item
            label="Tanggal Kirim"
            name="shipping_date"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker
              className="w-full"
              onChange={(_, dateString) => setShippingDate(dateString)}
            />
          </Form.Item>
        </div>

        <Divider />

        <div className="section-checkbox-resi">
          <Checkbox
            className="mb-2"
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
          >
            Select All
          </Checkbox>
          <br />

          <Checkbox.Group options={items} value={checkedItems} onChange={onChange} />
        </div>

        <div className="flex justify-end gap-2 mt-10">
          <Button className="border-0 bg-rose-500 text-white w-32" onClick={() => handleReset()}>
            Cancel
          </Button>
          <Form.Item>
            <Button
              htmlType="submit"
              disabled={loading || checkedItems.length === 0}
              className="border-0 bg-cyan-500 text-white w-32"
            >
              Simpan
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}

export default InputResi
