import { request } from '../config'

class LeadsService {
  static async getDatatable(params) {
    const response = await request.get('/leads', { params })

    return response.data
  }

  static async getDashboard(params) {
    const response = await request.get('/leads/dashboard', { params })

    return response.data
  }

  static async exportData(params) {
    const response = await request.get('/leads/export', { params })

    return response.data
  }

  static async changeStatusContact(id) {
    const { data: response } = await request.put(`/leads/${id}/contact-saved`)
    return response
  }

  static async changeCanBeContacted(id) {
    const { data: response } = await request.put(`/leads/${id}/contacted`)
    return response
  }

  static async changeStatusPurchased(id, data) {
    const { data: response } = await request.put(`/leads/${id}/purchased`, data)
    return response
  }

  static async insert(lead_id, data) {
    const { data: response } = await request.post(`/leads/${lead_id}/treatment`, data)
    return response
  }

  static async getDataReasonManagementLeads(data) {
    const { data: response } = await request.get(`/leads/reason`, data)
    return response
  }

  static async addLeadsReason(data) {
    const { data: response } = await request.post(`/lead-reason`, data)
    return response
  }

  static async inputLeads(data) {
    const { data: response } = await request.post(`/leads`, data)
    return response
  }

  static async importLeads(formData) {
    const { data: response } = await request.post(`/leads/import`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return response
  }

  static async updateLeads(data, leads_id) {
    const { data: response } = await request.put(`/leads/${leads_id}`, data)
    return response
  }

  static async removeLeads(id) {
    const { data: response } = await request.delete(`/leads/${id}`)
    return response
  }

  static async getPlatformList() {
    const { data: response } = await request.get('/platforms')
    return response
  }

  static async getManufacturers() {
    const { data: response } = await request.get('/brands/manufacturer')
    return response
  }

  static async getBrands(manufacturer_id) {
    const { data: response } = await request.get(`/brands/manufacturer/${manufacturer_id}`)
    return response
  }

  static async getDataLeadsReason(page, limit, sort, order, filter) {
    const response = await request.get('/lead-reason', {
      params: { page, limit, sort, order, filter },
    })

    return response.data
  }

  static async removeLeadsReason(id) {
    const { data: response } = await request.delete(`/lead-reason/${id}`)
    return response
  }

  static async dropdownFilter() {
    const response = await request.get(`/leads/options`)
    return response.data
  }

  static async searchVehicle(filter) {
    const response = await request.get(`/brands/search?keyword=${filter}`)
    return response.data
  }
}

export default LeadsService
