import { Suspense } from 'react'
import { Navigate, Outlet } from 'react-router'
import PageLoading from '../components/PageLoading'
import AuthLayout from '../layout/AuthLayout'
import DashboardLayout from '../layout/DashboardLayout'
import Login from '../pages/Auth/Login'
import Logout from '../pages/Auth/Logout'
import Dashboard from '../pages/Dashboard'
import Addons from '../pages/Master/Addons'
import Category from '../pages/Master/Category'
import AddCategory from '../pages/Master/Category/FormCategory/AddCategory'
import EditCategory from '../pages/Master/Category/FormCategory/EditCategory'
import Color from '../pages/Master/Color'
import ColorVariant from '../pages/Master/ColorVariant'
import Courier from '../pages/Master/Courier'
import Designs from '../pages/Master/Designs'
import Fiber from '../pages/Master/Fiber'
import FiberVariant from '../pages/Master/FiberVariant'
import Logo from '../pages/Master/Logo'
import Material from '../pages/Master/Material'
import MaterialVariant from '../pages/Master/MaterialVariant'
import PaymentChannel from '../pages/Master/PaymentChannel'
import AddPaymentChannel from '../pages/Master/PaymentChannel/FormPaymentChannel/AddPaymentChannel'
import Prices from '../pages/Master/Prices'
import Product from '../pages/Master/Product'
import AddProduct from '../pages/Master/Product/FormProduct/AddProduct'
import RolePermission from '../pages/Master/RolePermission'
import ManagePermission from '../pages/Master/RolePermission/ManagePermission'
import ManageStatus from '../pages/Master/RolePermission/ManageStatus'
import UserList from '../pages/Master/User'
import AddUser from '../pages/Master/User/FormSection/AddUser'
import VehicleClass from '../pages/Master/Vehicle/Class/VehicleClass'
import Manufacturer from '../pages/Master/Vehicle/Manufacturer/Manufacturer'
import AddVehicleModel from '../pages/Master/Vehicle/Model/Form/AddVehicleModel'
import EditVehicleModel from '../pages/Master/Vehicle/Model/Form/EditVehicleModel'
import VehicleModel from '../pages/Master/Vehicle/Model/VehicleModel'
import FormOrder from '../pages/Orders/FormOrder/FormOrder'
import TableOrder from '../pages/Orders/TableOrder'
import EditOrder from '../pages/Orders/TableOrder/EditOrder'
import PrintLabel from '../pages/Orders/TableOrder/PrintLabel'
import Finance from '../pages/Report/Finance'
import Progress from '../pages/Report/Progress'
import Scoreboard from '../pages/Scoreboard'
import StatusProduction from '../pages/StatusProduction'
import TableLeads from '../pages/Leads/TableLeads'
import FormLeads from '../pages/Leads/FormLeads'
import DashboardLeads from '../pages/Leads/DashboardLeads'
import BudgetDaily from '../pages/Report/Budget/BudgetDaily'
import BudgetRatio from '../pages/Report/Budget/BudgetRatio'
import Advertiser from '../pages/Report/Advertiser'
import Dealmaker from '../pages/Report/Dealmaker'
import Customer from '../pages/Customer'
import BroadcastMessage from '../pages/Broadcast/BroadcastMessage'
import OmzetDaily from '../pages/Report/OmzetDaily'

export const routeList = (isLoggedIn) => [
  {
    path: '/',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/auth/login" />,
    children: [
      {
        subject: 'Menu Dashboard',
        actions: 'read',
        path: '',
        key: '/',
        label: 'Dashboard',
        element: <Suspense fallback={<PageLoading />} children={<Dashboard />} />,
        icon: <i className="fa-solid fa-tv text-[#007F73] w-5" />,
      },
      {
        subject: 'Menu Report',
        actions: 'read',
        path: '',
        label: 'Laporan',
        icon: <i className="fa-solid fa-chart-bar text-indigo-500 pl-[1px] w-5" />,
        element: <Outlet />,
        children: [
          {
            subject: 'Report Finance',
            actions: 'read',
            icon: <i className="fa-solid fa-rupiah-sign text-[#1A4D2E] w-5 pl-[2.5px]" />,
            label: 'Keuangan',
            key: '/finance',
            path: 'finance',
            element: <Suspense children={<Finance />} />,
          },
          {
            subject: 'Progress Produksi',
            actions: 'export',
            icon: <i className="fa-solid fa-truck-ramp-box text-[#4F6F52] w-4" />,
            label: 'Progress Produksi',
            key: '/progress',
            path: 'progress',
            element: <Suspense children={<Progress />} />,
          },
          {
            subject: 'Report Budget Daily',
            actions: 'read',
            icon: <i className="fa-solid fa-hand-holding-dollar text-[#948979]" />,
            label: 'Budget Daily',
            key: '/budget-daily',
            path: 'budget-daily',
            element: <Suspense children={<BudgetDaily />} />,
          },
          {
            subject: 'Report Budget Ratio',
            actions: 'read',
            icon: <i className="fa-solid fa-percent text-[#3C5B6F]" />,
            label: 'Budget Ratio',
            key: '/budget-ratio',
            path: 'budget-ratio',
            element: <Suspense children={<BudgetRatio />} />,
          },
          {
            subject: 'Report Advertiser',
            actions: 'read',
            icon: <i className="fa-solid fa-ad text-[#655DBB]" />,
            label: 'Advertiser',
            key: '/advertiser',
            path: 'advertiser',
            element: <Suspense children={<Advertiser />} />,
          },
          {
            subject: 'Report Dealmaker',
            actions: 'read',
            icon: <i className="fa-solid fa-handshake text-[#CE5959]" />,
            label: 'Dealmaker',
            key: '/dealmaker',
            path: 'dealmaker',
            element: <Suspense children={<Dealmaker />} />,
          },
          {
            subject: 'Report Omzet Daily',
            actions: 'read',
            icon: <i className="fa-solid fa-money-bills text-green-500" />,
            label: 'Omzet Daily',
            key: '/omzet-daily',
            path: 'omzet-daily',
            element: <Suspense children={<OmzetDaily />} />,
          },
        ],
      },
      {
        subject: 'Menu Master',
        actions: 'read',
        path: '',
        key: '/master',
        label: 'Master',
        icon: <i className="fa-solid fa-box-open text-blue-500 w-5" />,
        element: <Outlet />,
        children: [
          {
            subject: 'User',
            actions: 'read',
            icon: <i className="fa-regular fa-user w-5 pl-[2.5px]" />,
            label: 'Pengguna',
            key: '/users',
            path: 'users',
            element: <Suspense children={<UserList />} />,
          },
          {
            path: 'users/add',
            element: <Suspense children={<AddUser />} />,
          },
          {
            subject: 'Permission',
            actions: 'manage',
            icon: <i className="fa-solid fa-people-carry-box w-5" />,
            label: 'Role Permissions',
            path: 'roles',
            key: '/roles',
            element: <Suspense children={<RolePermission />} />,
          },
          {
            path: 'roles/:id',
            element: <Suspense children={<ManagePermission />} />,
          },
          {
            path: 'role-statuses/:id',
            element: <Suspense children={<ManageStatus />} />,
          },
          {
            subject: 'Payment Channel',
            actions: 'read',
            icon: <i className="fa-solid fa-credit-card w-5" />,
            label: 'Channel Pembayaran',
            path: 'payment-channels',
            key: '/payment-channels',
            element: <Suspense children={<PaymentChannel />} />,
          },
          {
            path: 'payment-channels/add',
            element: <Suspense children={<AddPaymentChannel />} />,
          },
          {
            subject: 'Courier',
            actions: 'read',
            icon: <i className="fa-solid fa-truck-fast w-5" />,
            label: 'Kurir',
            path: 'couriers',
            key: '/couriers',
            element: <Suspense children={<Courier />} />,
          },
          {
            subject: 'Product',
            actions: 'read',
            icon: <i className="fa-solid fa-boxes-stacked w-5 pl-[1px]" />,
            label: 'Master Produk',
            path: 'master-product',
            // key: '/master-product',
            element: <Outlet />,
            children: [
              {
                subject: 'Category',
                actions: 'read',
                icon: <i className="fa-regular fa-rectangle-list w-5 pl-[1px]" />,
                label: 'Kategori',
                path: 'categories',
                key: '/master-product/categories',
                element: <Suspense children={<Category />} />,
              },
              {
                path: 'categories/add',
                element: <Suspense children={<AddCategory />} />,
              },
              {
                path: 'categories/:id',
                element: <Suspense children={<EditCategory />} />,
              },
              {
                subject: 'Manufacturer',
                actions: 'read',
                icon: <i className="fa-solid fa-truck-ramp-box" />,
                label: 'Produk',
                key: 'master-product/products',
                path: 'products',
                element: <Suspense children={<Product />} />,
              },
              {
                path: 'products/add',
                element: <Suspense children={<AddProduct />} />,
              },
              {
                path: 'products/:id/variants',
                element: <Suspense children={<Prices />} />,
              },
              {
                path: 'products/:id/materials',
                element: <Suspense children={<Material />} />,
              },
              {
                path: 'products/:id/material-variants',
                element: <Suspense children={<MaterialVariant />} />,
              },
              {
                path: 'materials/:id/fibers',
                element: <Suspense children={<Fiber />} />,
              },
              {
                path: 'material-variants/:id/fiber-variants',
                element: <Suspense children={<FiberVariant />} />,
              },
              {
                path: 'fibers/:id/colors',
                element: <Suspense children={<Color />} />,
              },
              {
                path: 'fiber-variants/:id/color-variants',
                element: <Suspense children={<ColorVariant />} />,
              },
              {
                path: 'products/:id/addons',
                element: <Suspense children={<Addons />} />,
              },
              {
                path: 'products/:id/designs',
                element: <Suspense children={<Designs />} />,
              },
              {
                path: 'products/:id/logos',
                element: <Suspense children={<Logo />} />,
              },
              {
                subject: 'Vehicle Model',
                actions: 'read',
                icon: <i className="fa-solid fa-truck-monster" />,
                label: 'Tipe Mobil',
                key: 'master-product/vehicle/models',
                path: 'vehicle/models',
                element: <Suspense children={<VehicleModel />} />,
              },
              {
                path: 'vehicle/models/add',
                element: <Suspense children={<AddVehicleModel />} />,
              },
              {
                path: 'vehicle/models/:id',
                element: <Suspense children={<EditVehicleModel />} />,
              },
              {
                subject: 'Vehicle Class',
                actions: 'read',
                icon: <i className="fa-solid fa-car-side" />,
                label: 'Kelas Kendaraan',
                key: 'master-product/vehicle/class',
                path: 'vehicle/class',
                element: <Suspense children={<VehicleClass />} />,
              },
              {
                subject: 'Manufacturer',
                actions: 'read',
                icon: <i className="fa-solid fa-city" />,
                label: 'Pabrikan',
                key: 'master-product/vehicle/manufacturers',
                path: 'vehicle/manufacturers',
                element: <Suspense children={<Manufacturer />} />,
              },
            ],
          },
        ],
      },
      {
        subject: 'Leads',
        actions: 'read',
        label: 'Leads',
        key: 'leads',
        icon: <i className="fa-solid fa-ad ant-menu-item-selected bg-transparent text-green-500" />,
        element: <Outlet />,
        children: [
          {
            subject: 'Leads',
            actions: 'read',
            icon: <i className="fa-solid fa-tv ant-menu-item-selected" />,
            label: 'Dashboard Leads',
            key: '/leads/dashboard',
            path: 'leads/dashboard',
            element: <Suspense children={<DashboardLeads />} />,
          },
          {
            subject: 'Leads',
            actions: 'read',
            icon: <i className="fa-regular fa-rectangle-list ant-menu-item-selected" />,
            label: 'Table Leads',
            key: '/leads',
            path: 'leads',
            element: <Suspense children={<TableLeads />} />,
          },
          {
            path: 'leads/add',
            element: <Suspense children={<FormLeads />} />,
          },
        ],
      },
      {
        subject: 'Customer',
        actions: 'read',
        path: 'customers',
        key: '/customers',
        label: 'Customer',
        element: <Suspense fallback={<PageLoading />} children={<Customer />} />,
        icon: <i className="fa-solid fa-user-group text-base text-orange-500" />,
      },
      {
        subject: 'Order',
        actions: 'create',
        path: 'orders',
        key: '/orders',
        label: 'Form Order',
        element: <Suspense fallback={<PageLoading />} children={<FormOrder />} />,
        icon: <i className="fa-solid fa-laptop-file text-base text-rose-500 w-5" />,
      },
      {
        subject: 'Order',
        actions: 'read',
        path: 'table/orders',
        key: '/table/orders',
        label: 'Table Order',
        element: <Suspense fallback={<PageLoading />} children={<TableOrder />} />,
        icon: <i className="fa-regular fa-rectangle-list text-base text-[#4F6F52] w-5" />,
      },
      {
        path: 'table/orders/:id',
        element: <Suspense fallback={<PageLoading />} children={<EditOrder />} />,
      },
      {
        subject: 'Barcode',
        actions: 'scan',
        path: 'barcode',
        key: '/barcode',
        label: 'Scan Barcode',
        element: <Suspense fallback={<PageLoading />} children={<StatusProduction />} />,
        icon: <i className="fa-solid fa-camera text-base text-cyan-500 w-5" />,
      },
      {
        subject: 'Broadcast',
        actions: 'send',
        path: 'broadcast',
        key: 'broadcast',
        label: 'Broadcast',
        element: <Suspense fallback={<PageLoading />} children={<BroadcastMessage />} />,
        icon: <i className="fa-solid fa-broadcast-tower text-orange-500 w-5" />,
      },
    ],
  },
  {
    path: 'logout',
    element: <Logout />,
  },
  {
    path: '/scoreboard',
    element: isLoggedIn ? <Scoreboard /> : <Navigate to="/auth/login" />,
    children: [
      {
        path: 'scoreboard',
        element: <Suspense children={<Scoreboard />} />,
      },
    ],
  },
  {
    path: '/print/label/:id',
    element: isLoggedIn ? <PrintLabel /> : <Navigate to="/auth/login" />,
    children: [
      {
        path: 'print/label/:id',
        element: <Suspense children={<PrintLabel />} />,
      },
    ],
  },
  {
    path: '/auth',
    element: !isLoggedIn ? <AuthLayout /> : <Navigate to="/" />,
    children: [
      {
        path: 'login',
        element: <Suspense children={<Login />} />,
      },

      { index: true, element: <Navigate to="/auth/login" /> },
    ],
  },
]
