import { Button, Card, DatePicker, Form, Input, Select, Switch, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import AsyncSelect from 'react-select/async'
import CustomBadge from '../../../components/CustomBadge'
import CustomerService from '../../../services/CustomerService'
import LeadsService from '../../../services/LeadsService'
import OrderService from '../../../services/OrderService'
import RegionalService from '../../../services/RegionalService'

const state = {
  address: '',
  province_id: '',
  district_id: '',
  subdistrict_id: '',
  postal_code: '',
}

const FormLeads = () => {
  const [form] = Form.useForm()

  const navigate = useNavigate()

  const user = useSelector((state) => state.auth.user)

  const year = new Date().getFullYear()
  const years = Array.from(new Array(70), (val, index) => year - index)

  const [values, setValues] = useState(state)

  const [loading, setLoading] = useState(false)
  const [first_chat, setFirstChat] = useState('')
  const [platform, setPlatform] = useState('')
  const [reason, setReason] = useState('')
  const [purchased, setPurchased] = useState(false)
  const [customerType, setCustomerType] = useState([])
  const [requiredPhone, setRequiredPhone] = useState(true)

  const [provinces, setProvinces] = useState([])
  const [district, setDistrict] = useState([])
  const [subdistricts, setSubdistricts] = useState([])

  useEffect(() => {
    form.setFieldsValue({
      user_phone: user.phone,
      dealmaker: user.name,
    })
    getProvince()
    getDataCustomerTypeList()
    getDataPlatform()
    getDataReason()
  }, [form, user])

  const loadVehicleOptions = async (inputValue, callback) => {
    if (inputValue.length >= 2) {
      const response = await LeadsService.searchVehicle(inputValue)
      let options = []

      for (let row of response.data) {
        options.push({
          manufacturer: row.manufacturer.name,
          brand: row.name,
          label: `${row.manufacturer.name} ${row.name}`,
          value: `${row.manufacturer.name} ${row.name}`,
        })
      }
      callback(options)
    }
  }

  const getDataCustomerTypeList = async () => {
    try {
      setLoading(true)
      const { data } = await CustomerService.customerTypeList()
      setCustomerType(data.map((item) => ({ label: item.name, value: item.id })))
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const getDataPlatform = async () => {
    try {
      setLoading(true)
      const response = await OrderService.getOrderSources()
      if (response.status === 200) {
        setPlatform(response.data.map((item) => ({ label: item.name, value: item.name })))
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const getDataReason = async () => {
    try {
      setLoading(true)
      const { data } = await LeadsService.getDataReasonManagementLeads()
      setReason(data.map((item) => ({ label: item, value: item })))
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const getProvince = async () => {
    try {
      const response = await RegionalService.province()
      setProvinces(response.data.map((item) => ({ label: item.name, value: item.id })))
    } catch (error) {
      console.log(error)
    }
  }

  const provinceChange = async (value) => {
    setValues({ ...values, district_id: '', province_id: value })
    try {
      const response = await RegionalService.districtByProvince(value)
      setDistrict(response.data.map((item) => ({ label: item.name, value: item.id })))
      form.resetFields(['district_id', 'subdistrict_id'])
    } catch (error) {
      console.log(error)
    }
  }

  const districtChange = async (value) => {
    setValues({ ...values, subdistricts_id: '', district_id: value })

    try {
      const response = await RegionalService.subdistrictByDistrict(value)
      setSubdistricts(response.data.map((item) => ({ label: item.name, value: item.id })))
      form.resetFields(['subdistrict_id'])
    } catch (error) {
      console.log(error)
    }
  }

  const onFinish = async (values) => {
    setLoading(true)

    const payload = {
      first_chat: first_chat,
      customer_phone: values.customer_phone ? `+62` + values.customer_phone : null,
      customer_name: values.customer_name,
      manufacturer: values.vehicle?.manufacturer,
      brand: values.vehicle?.brand,
      year: values.year || '2024',
      platform: values.platform || 'Meta',
      contact_saved: values.contact_saved,
      can_be_contacted: values.can_be_contacted,
      reason: values.reason || 'Mahal',
      purchased: purchased,
      customer_type_id: values.customer_type_id,
      province_id: values.province_id || null,
      district_id: values.district_id || null,
      subdistrict_id: values.subdistrict_id || null,
      postal_code: values.postal_code,
      address: values.address,
    }

    try {
      const response = await LeadsService.inputLeads(payload)

      if (response.status === 200) {
        notification.success({
          message: 'Berhasil',
          description: 'Data Leads berhasil disimpan',
        })
      }
      setLoading(false)
      navigate('/leads')
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <>
      <CustomBadge formOrder title="Form Leads" titleParent="Leads" />

      <Card className="md:ml-3">
        <Form
          layout="vertical"
          className="grid md:grid-cols-3 gap-4"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item name="user_phone" label="No. Whatsapp Cs">
            <Input
              readOnly
              placeholder="No. Whatsapp Cs"
              onChange={(e) =>
                form.setFieldsValue({
                  ...form.getFieldsValue,
                  user_phone: e.target.value
                    .replace(/[^0-9]/gi, '')
                    .replace(/^0/gi, '')
                    .replace(/^62/gi, ''),
                })
              }
              addonBefore="+62"
            />
          </Form.Item>

          <Form.Item name="dealmaker" label="Dealmaker">
            <Input readOnly placeholder="Dealmaker" />
          </Form.Item>

          <Form.Item
            label="Tanggal Leads Masuk"
            name="first_chat"
            required
            rules={[
              {
                required: true,
                message: 'Tanggal Leads Masuk harus diisi',
              },
            ]}
          >
            <DatePicker
              className="w-full"
              placeholder="Pilih Tanggal"
              format="YYYY-MM-DD"
              onChange={(date, dateString) => setFirstChat(dateString)}
            />
          </Form.Item>

          <Form.Item
            name="customer_name"
            label="Nama Customer"
            required
            rules={[
              {
                required: true,
                message: 'Nama Customer harus diisi',
              },
            ]}
          >
            <Input placeholder="Nama Customer" />
          </Form.Item>

          <Form.Item
            name="customer_phone"
            label="No. Whatsapp Customer"
            required={requiredPhone}
            rules={[
              {
                required: requiredPhone,
                message: 'No. Whatsapp Customer harus diisi',
              },
            ]}
          >
            <Input
              placeholder="No. Whatsapp Customer"
              onChange={(e) =>
                form.setFieldsValue({
                  ...form.getFieldsValue,
                  customer_phone: e.target.value
                    .replace(/[^0-9]/gi, '')
                    .replace(/^0/gi, '')
                    .replace(/^62/gi, ''),
                })
              }
              addonBefore="+62"
            />
          </Form.Item>

          <Form.Item
            name="vehicle"
            label="Kendaraan Customer"
            required
            rules={[
              {
                required: true,
                message: 'Kendaraan harus diisi',
              },
            ]}
          >
            <AsyncSelect placeholder="Nama Kendaraan" loadOptions={loadVehicleOptions} />
          </Form.Item>

          <Form.Item
            name="year"
            label="Tahun"
            required
            rules={[
              {
                required: true,
                message: 'Tahun harus diisi',
              },
            ]}
          >
            <Select
              placeholder="Tahun"
              options={years?.map((item) => ({
                value: item,
                label: item,
              }))}
            />
          </Form.Item>

          <Form.Item
            name="platform"
            label="Platform"
            required
            rules={[
              {
                required: true,
                message: 'Platform harus diisi',
              },
            ]}
          >
            <Select
              options={platform}
              placeholder="Pilih Platform"
              onChange={(value) => {
                if (['DM Instagram', 'Messenger Facebook', 'DM TikTok'].includes(value)) {
                  setRequiredPhone(false)
                } else {
                  setRequiredPhone(true)
                }
              }}
            />
          </Form.Item>

          <Form.Item label="Sudah Beli">
            <Switch onChange={(checked) => setPurchased(checked)} />
          </Form.Item>

          {purchased ? (
            <>
              <Form.Item
                name="customer_type_id"
                label="Jenis Customer"
                required
                rules={[
                  {
                    required: true,
                    message: 'Jenis Customer harus diisi',
                  },
                ]}
              >
                <Select placeholder="Pilih Jenis Customer" options={customerType} />
              </Form.Item>

              <Form.Item
                name="province_id"
                label="Provinsi"
                required
                rules={[
                  {
                    required: true,
                    message: 'Provinsi harus diisi',
                  },
                ]}
              >
                <Select
                  placeholder="Pilih Provinsi"
                  options={provinces}
                  onChange={provinceChange}
                />
              </Form.Item>

              <Form.Item
                name="district_id"
                label="Kota / Kabupaten"
                required
                rules={[
                  {
                    required: true,
                    message: 'Kota / Kabupaten harus diisi',
                  },
                ]}
              >
                <Select
                  placeholder="Pilih Kota / Kabupaten"
                  options={district}
                  onChange={districtChange}
                />
              </Form.Item>

              <Form.Item
                name="subdistrict_id"
                label="Kecamatan"
                required
                rules={[
                  {
                    required: true,
                    message: 'Kecamatan harus diisi',
                  },
                ]}
              >
                <Select placeholder="Pilih Kecamatan" options={subdistricts} />
              </Form.Item>

              <Form.Item
                label="Kode Pos"
                name="postal_code"
                required
                rules={[
                  {
                    required: true,
                    min: 5,
                  },
                ]}
              >
                <Input maxLength={5} placeholder="123xx" />
              </Form.Item>

              <Form.Item
                name="address"
                label="Alamat"
                required
                rules={[
                  {
                    required: true,
                    message: 'Alamat harus diisi',
                  },
                ]}
              >
                <Input.TextArea placeholder="Alamat" rows={3} />
              </Form.Item>
            </>
          ) : (
            <Form.Item
              name="reason"
              label="Alasan Belum Beli"
              required
              rules={[
                {
                  required: true,
                  message: 'Alasan Belum Beli harus diisi',
                },
              ]}
            >
              <Select placeholder="Pilih Alasan Belum Beli" options={reason} />
            </Form.Item>
          )}

          <Form.Item name="contact_saved" label="Save Kontak">
            <Switch />
          </Form.Item>

          <Form.Item name="can_be_contacted" label="Nomor Dapat Dihubungi">
            <Switch />
          </Form.Item>

          <Form.Item className="md:col-span-3 md:flex md:justify-end">
            <Button
              className="bg-rose-500 border-0 text-white mr-2"
              onClick={() => navigate('/leads')}
            >
              Batal
            </Button>

            <Button
              loading={loading}
              htmlType="submit"
              className="bg-[#205295] border-0 text-white"
            >
              Simpan
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  )
}

export default FormLeads
