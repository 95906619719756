import { Button, Form, Input, Modal, Radio, Select, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import UserService from '../../../../services/UserService'
import {
  ROLE_ADMIN,
  ROLE_ADMIN_PRODUCTION,
  ROLE_CS_SUPERVISOR,
  ROLE_DEALMAKER,
  ROLE_MARKETING,
} from '../../../../constants'

const EditUser = (props) => {
  const { open, row, toggle } = props

  const [form] = Form.useForm()

  const [roleOptions, setRoleOptions] = useState([])
  const [unitOptions, setUnitOptions] = useState([])

  useEffect(() => {
    if (open) {
      getListRole()
      getListUnit()

      form.setFieldsValue({
        name: row.name,
        email: row.email,
        role_id: row.role_id,
        unit_id: row.units.length === 1 ? row.units[0] : undefined,
      })
    }
  }, [form, row, open])

  const getListRole = async () => {
    try {
      const response = await UserService.listRole()

      if (response.status === 200) {
        setRoleOptions(
          response.data.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getListUnit = async () => {
    try {
      const response = await UserService.listUnit()

      if (response.status === 200) {
        setUnitOptions(
          response.data.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const allCheckedActive = () => {
    let data = unitOptions.map((item) => item.value)
    return form.setFieldsValue({
      units: data,
    })
  }

  const handleRoleChange = (value) => {
    if ([ROLE_ADMIN, ROLE_ADMIN_PRODUCTION, ROLE_MARKETING, ROLE_CS_SUPERVISOR].includes(value)) {
      allCheckedActive()
    } else {
      form.resetFields(['unit_id', 'units'])
    }
  }

  // const onValuesChange = (changedValues, allValues) => {
  //   console.log('changedValues', changedValues)
  //   console.log('allValues', allValues)
  // }

  const onFinish = async (values) => {
    const payload = {
      name: values.name,
      role_id: values.role_id,
      units: [values.unit_id],
    }

    try {
      const response = await UserService.update(row.id, payload)

      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: 'Data berhasil diubah',
        })

        toggle()
        form.resetFields()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal title="Edit Pengguna" open={open} onCancel={toggle} footer={false}>
      <Form form={form} layout="vertical" onFinish={onFinish} className="my-5">
        <Form.Item
          label="Nama Pengguna"
          name="name"
          required
          rules={[
            {
              required: true,
              message: 'Nama Pengguna harus diisi',
            },
          ]}
        >
          <Input placeholder="Silahkan isi nama" />
        </Form.Item>

        <Form.Item
          label="E-mail"
          name="email"
          required
          rules={[
            {
              required: true,
              message: 'E-mail harus diisi',
            },
          ]}
        >
          <Input disabled placeholder="Silahkan isi e-mail" />
        </Form.Item>

        <Form.Item label="Sebagai" name="role_id">
          <Select
            allowClear
            placeholder="Pilih"
            options={roleOptions}
            onChange={handleRoleChange}
          />
        </Form.Item>

        {[ROLE_DEALMAKER].includes(row?.role_id) && (
          <Form.Item
            label="Pilih Unit"
            name="unit_id"
            rules={[
              {
                required: true,
                message: 'Unit pengguna harus diisi',
              },
            ]}
            required
          >
            <Radio.Group options={unitOptions} />
          </Form.Item>
        )}

        <div className="flex justify-end gap-2">
          <Form.Item>
            <Button onClick={toggle} className="bg-rose-500 text-white border-0">
              Cancel
            </Button>
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" className="bg-green-500 text-white border-0">
              Simpan
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}

export default EditUser
